import { useEffect, useMemo } from 'react'
import './styles.css'
import { Menu } from '../../menu'
import { menuLabels, risksConfig } from '@/config/risks.config'
import { useRisksCategory } from '@/core/hooks'
import { CategoryRisks, Languages, SubcategoryRisks } from '@/core/typing'
import logo from '@/assets/images/logo.svg'
import labelLogo from '@/assets/images/label-travy.svg'
import { Icon } from '../../icons'
import _ from 'lodash'
import { SearchCity, SearchCountry } from '@/core/widgets'
import { useAccount } from '@/modules/user/hooks'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/store'
import { appEvents } from '@/core/events'
import { AboutUsBtn } from '@/modules/root/components'

interface IProps {
    isOpen: boolean
    toggleBar: () => void
}
export const LeftSideBar = ({ isOpen, toggleBar }: IProps) => {
    const { t } = useTranslation()
    const { lang } = useLanguageStore()
    const { account } = useAccount()
    const { active, getActiveCategory, setCategoryRisks } = useRisksCategory()

    useEffect(() => {
        getActiveCategory()
    }, [])

    const onClickRisk = (val: { category: string; subcategory?: string }) => {
        setCategoryRisks(
            val as { category: CategoryRisks; subcategory: SubcategoryRisks },
        )
    }

    const getLabel = () => {
        if (!active) {
            return menuLabels[CategoryRisks.Owerview]
        } else if (active?.category && active?.subcategory) {
            return menuLabels[active?.subcategory]
        } else {
            return menuLabels[active?.category]
        }
    }

    const openContactUs = () => {
        appEvents.emit('contactUs', { isShow: true })
    }
    const searchCountry = useMemo(() => {
        if (
            !account ||
            (account && !account?.subscription) ||
            active?.category === CategoryRisks.Requirements ||
            active?.subcategory === SubcategoryRisks.SeaLevelRise ||
            !isOpen
        ) {
            return null
        } else if (active?.subcategory === SubcategoryRisks.Weather) {
            return (
                <div className={'search_country_full'}>
                    <SearchCity />
                </div>
            )
        }
        return (
            <div className={'search_country_full'}>
                <SearchCountry />
            </div>
        )
    }, [active, isOpen, account])

    return (
        <div className={`left-sidebar ${isOpen ? 'open' : ''}`}>
            <div className='header_container'>
                <Icon
                    className='sidebar_toggle'
                    onClick={toggleBar}
                    name={isOpen ? 'left-arrow' : 'right-arrow'}
                    size={24}
                    color='#717375'
                />
                <p
                    className={`category_label ${isOpen ? 'hidden_category' : ''}`}>
                    {t(`${getLabel()}`)}
                </p>
            </div>
            {searchCountry}
            <div className='sidebar-content'>
                <div
                    className='logo_container'
                    onClick={() =>
                        setCategoryRisks({
                            category: CategoryRisks.Owerview,
                            subcategory: null,
                        })
                    }>
                    <img src={logo} className='logo_guido' />
                    <img
                        src={labelLogo}
                        className={isOpen ? 'logo_label' : 'hide_logo_label'}
                    />
                </div>

                <div>
                    <>
                        {risksConfig.map((it, index) => (
                            <Menu
                                id={`category-${index}`}
                                indexMenu={index}
                                activeCategory={active}
                                onClickMenu={onClickRisk}
                                key={it.key}
                                item={it}
                                close={_.noop}
                            />
                        ))}
                    </>
                </div>

                <div className='rights_container'>
                    <div className={'contact_us'} onClick={openContactUs}>
                        <AboutUsBtn isOpen={isOpen} />
                    </div>

                    <p
                        style={{
                            textWrap: lang !== Languages.EN ? 'wrap' : 'nowrap',
                        }}
                        className={!isOpen ? 'collapsed' : 'rights show'}>
                        © 2024 SafeTravy. {t('rightsReserved')}.
                    </p>

                    <p className={isOpen ? 'collapsed' : 'rights show'}>
                        ©SafeTravy
                    </p>
                </div>
            </div>
        </div>
    )
}
