import { EntryCountries } from '@/modules/entry-requirements/typing'
import { INotification } from '@/modules/notifications/typing'
import { Events } from 'jet-tools'

export type AppEvents = {
    event: {
        data: string
    }
    subsribe: {
        isShow: boolean
        method?: 'register' | 'buy'
        isUpdate?: boolean
    }
    guidoBot: { isShow: boolean }
    notifications: { isShow?: boolean; notification?: INotification }
    resetNotification: any
    requrements: { isShow: boolean; data: EntryCountries }
    seaLevel: { level?: number; isShow?: boolean }
    settingsNotifications: { isShow: boolean }
    account: { isShow: boolean }
    questionare: { show: boolean }
    intro: { isShow: boolean }
    greeting: { isShow: boolean }
    requestSubscribe: { isShow: boolean }
    contactUs: { isShow: boolean }
}
export const appEvents = new Events<AppEvents>()

export type SocketEvents = {
    'notification/new-notification': { data: any }
    stopSessions: { data: any }
    'subscription/new': { data: any }
    'subscription/cancelled': { data: any }
    'subscription/changed': { data: any }
}
export const socketEvents = new Events<SocketEvents>()
