import { Taranslates } from '../interfaces'

export const translationES: Taranslates = {
    //profile
    cancel: 'Cancelar',
    profile: 'Perfil',
    edit: 'Modificar',
    fullname: 'Vollständiger Name',
    countryResidence: 'País de residencia',
    gender: 'Gender',
    man: 'Hombre',
    woman: 'Mujer',
    email: 'Email',
    citіzenship: 'Ciudadanía',
    birthday: 'Fecha de nacimiento',
    citizenship: 'Ciudadanía',

    notifications: 'Notificationes',
    chooseNotifications: 'Elija el tipo de notificaciones que desea recibir',
    geoConflicts: 'Conflictos geopolíticos',
    internalConflicts: 'Tensiones sociales internas',
    potentialInsecurity: 'Inseguridad potencial',
    climate: 'Riesgos climáticos',
    medical: 'Riesgos médicos',
    methodNotifications: 'Elija el método para recibir las notificaciones',
    onEmail: 'Por e-mail',
    pushNotifications: 'Push-notification',
    infoUseEmail:
        'Por favor ingrese su correo electrónico para recibir información importante',
    errorExistEmail: 'El correo electrónico ya está registrado',
    followCountries: 'Seguir por países',
    chatBotSettings: 'Configuración del chatbot',
    chatHistoryToEmail:
        'Envía el historial del chatbot a tu correo electrónico',
    allow: 'Permitir',
    deny: 'Denegar',

    sureLogout: 'Está seguro de que desea',
    toLogout: 'cerrar sesión',
    logout: 'Cerrar sesión',
    back: 'Volver',

    //Subscribtion settings
    subscription: 'Suscripción',
    cancelSubscribtionBtn: 'Cancelar suscripción',
    updateSubscriptionBtn: 'Actualizar suscripción',
    yourSubscribtion: 'Su suscripción',
    validUntil: 'Válido hasta el',
    historySubscription: 'Historial de suscripciones',
    privacyPolicy: 'Política de privacidad',
    termsService: 'Condiciones de servicio',
    rightsReserved: 'Todos los derechos reservados',
    changePassword: 'Cambiar la contraseña',
    save: 'Sí',
    accountOverview: 'Descripción de cuenta',
    // Overview
    overview: 'Perspectiva',
    conflicts: 'Conflictos',
    dangerPotential: 'Peligro potencial',
    social: 'Cuestiones sociales',
    standardLiving: 'Nivel de vida',
    foodDrinking: 'Suministro de alimentos y agua potable',
    humanCapitalDev: 'Desarrollo del capital humano',
    medicalSituation: 'Situación médica',
    pandemics: 'Pandemias y epidemias',
    weather: 'Meteorología',
    seaLevel: 'Aumento del nivel del mar',
    requirements: 'Requisitos de entrada',
    fromCountry: 'Del país',
    toCountry: 'Al país',
    hightRisk: 'Alta',
    middleRisk: 'Medio',
    lowRisk: 'Bajo',
    notMonitored: 'No controlado',

    searchCountries: 'Buscar países',
    searchCity: 'Buscar ciudad',
    viewAll: 'Ver todo',
    allNotifications: 'Todas las notificaciones',
    forCountries: 'Por países',
    showMore: 'Mostrar más',

    editInAccount: 'Puedes editar estos ajustes en tu cuenta personal',
    setupNotifications: 'Configura tus notificaciones',
    errorSelectCountry: 'Debes seleccionar al menos 1 país',
    sureSubscribtion: '¿Estás seguro de que quieres',
    cancelSubscribtion: 'cancelar tu suscripción',
    unsubscribe: 'Cancelar la suscripción',
    canSubscribe: 'Puedes volver a suscribirte en cualquier momento',
    successCancelSubscribtion:
        'Solicitud recibida. Tendrás acceso hasta el final de la suscripción actual.',
    successUpdateAccount: 'Actualización de cuenta exitosa',

    selectGender: 'Selecciona género',
    disabledEmail: 'No se puede editar el correo electrónico',

    findCountries: 'Buscar países',
    subscribtionNotSelected: 'No seleccionado',

    // Instruction
    conflictsInstruction:
        'Antes de visitar un nuevo país, hay varias cuestiones que podrían interesarte. En primer lugar, la seguridad, por lo que los posibles conflictos armados supondrían un duro golpe para tus planes. Elige la sección Conflictos y encuentra todos los lugares donde hay peligro de conflictos armados. ',
    socialIstruction:
        'Información sobre temas sociales que pueden afectar a su seguridad. El procedimiento es el mismo: elija uno de los temas, el color del país le indicará el nivel de riesgo, y al hacer clic en el país se abrirá la información pertinente. ',
    healthInstruction:
        'El mismo procedimiento se aplica a la sección Médica. Infórmese sobre los posibles factores médicos y su repercusión en su seguridad.',
    climateInstruction:
        'Compruebe el tiempo y la posibilidad de que se produzcan fenómenos naturales extremos en el país.',
    requirementsInstruction:
        'Quiere saber cómo llegar al país? Vaya a la sección Requisitos de entrada. Elige el país de salida y de llegada de la lista, haz clic en Buscar y recibe todos los datos necesarios, empezando por los requisitos de visado y terminando con el periodo de estancia en el país.',
    legendInstruction:
        'El color rojo del país en el mapa indica un peligro extremadamente alto. En nuestro caso, de un conflicto armado. El amarillo representa un riesgo considerable pero no inminente. El verde es para zona segura. El gris significa que hay mucho que conocer en este mundo. ',
    legend: 'Leyenda',
    chatBotInstruction:
        'Quieres ayuda más específica? Compra Extra y pregúntale a Travy, ¡tu compañero asistido por IA! Para la primera toma de contacto, pasa una breve encuesta para obtener un enfoque más personalizado de Travy. Después, ¡no dudes en hacer tus preguntas a Travy!',
    chatBot: 'Chat-bot',
    map: 'Mapa:',
    mapInstructions:
        'En esta sección, elija uno de los siguientes temas: conflictos geopolíticos en curso peligros sociales internos peligro potencial de conflicto armado. El color mostrará el nivel de riesgo de cada uno de estos temas. A continuación, haga clic en un país y lea información actualizada sobre los detalles del conflicto',
    notificationsInstruction:
        'Reciba alertas en tiempo real sobre peligros en determinados países',
    personalAccount: 'Cuenta personal',
    accountInstruction:
        'Quieres mantenerte informado? Suscríbase a las notificaciones en su perfil.',

    // Notes
    artificialIntelligenc:
        'El artículo es generado por inteligencia artificial. Consulta los datos importantes.',
    personalizedData: 'Datos personalizados actualizados',
    search: 'Buscar',

    consideration: 'El chatbot no tendrá en cuenta tus respuestas.',
    quit: 'Salir',

    // Tariffs
    basic: 'Básico',
    conflictsWorldwide: 'Conflictos mundiales',
    socialIssues: 'Cuestiones sociales',
    healthLabel: 'Salud',
    climateLabel: 'Clima',
    monthlyDataUpdates: 'Actualización diaria y mensual de datos',
    visaEntry: 'Requisitos generales de visado/entrada',
    emergencyPush: 'Notificaciones push de emergencia ',
    freeTrial: 'Prueba gratuita - 3 días',

    extra: 'Extra ',
    tailoredAI: 'Asistente de IA a medida',
    sightsAI: 'Consultor Sights AI',
    hotelTicketsAI: 'Hotel&Tickets AI Advisor',
    personalizedVisa: 'Requisitos personalizados de visado/entrada',
    noTrial: 'No hay prueba gratuita, se paga por adelantado',
    basic3: 'Básico 3 meses',
    extra3: '3 meses adicionales',
    popular: 'Popular',
    getStarted: 'Empezar',
    titleSubscribes: 'Explora el mundo con SafeTravy',
    benefits: 'Beneficios',
    month: 'mes',
    buy: 'Comprar',
    currentTariff: 'Plan tarifario actual',
    notHistory: 'Aún no hay suscripciones históricas',
    newSubscribtionMessage:
        'Solicitud recibida. La nueva suscripción se activará en unos minutos.',
    upgradeSubscribtionMessage:
        'Solicitud recibida. La nueva suscripción se activará en unos minutos.',
    downgradeSubscribtionMessage:
        'Solicitud recibida. La nueva suscripción se activará cuando caduque la actual.',
    tariffPlans: 'Planes tarifarios',

    // Questionare
    titleTravy: '¡Hola! Soy Travy',
    chooseVariant: 'Elija lo que quiera',
    personalisedAnswer:
        'Responda a la encuesta para obtener respuestas más personalizadas',
    yourQuestions: 'Formule su pregunta',
    purpouse: '¿Cuál es el objetivo de su viaje?',
    leisure: 'Leisure',
    business: 'Empresas',
    familyAffairs: 'Asuntos familiares/personales',
    next: 'Contiguous',

    transportationKind:
        '¿Qué medio de transporte prefiere para llegar a su destino?',
    landTransportation: 'Transporte terrestre',
    airTransportation: 'Transporte aéreo',
    seaTransportation: 'Transporte marítimo',

    accommodation: '¿Qué tipo de alojamiento prefiere?',
    hotel: 'Hotel',
    apartament: 'Apartamento',
    hostel: 'Albergue',
    holidayHome: 'Casa de vacaciones',

    activities: '¿Qué tipo de actividades le interesan?',
    historicalSites: 'Visitar lugares históricos',
    parks: 'Parques y reservas naturales',
    beach: 'Vacaciones en la playa',
    shopping: 'Compras',
    nightlife: 'Vida nocturna',
    sports: 'Actividades deportivas',
    culinary: 'Visitas gastronómicas',

    considerations:
        '¿Tiene algún requisito o consideración especial que debamos tener en cuenta?',
    dietary: 'Necesidades alimentarias',
    mobilityAssistance: 'Ayuda a la movilidad',
    medicalConditions: 'Condiciones médicas o consideraciones sanitarias',
    sensoryAccommodations: 'Alojamientos sensoriales',
    no: 'No',

    specificFears:
        '¿Tiene algún temor o preocupación específica que debamos tener en cuenta?',
    heights: 'Miedo a las alturas',
    fearSpaces: 'Miedo a los espacios cerrados o abarrotados',
    flying: 'Miedo a volar',
    water: 'Miedo al agua o a viajar por mar',
    animals: 'Miedo a insectos o animales',
    culturalDifferences: 'Preocupación por las diferencias culturales',

    thank: '¡Gracias!',
    thanYouMessage:
        'Tus respuestas se tendrán en cuenta cuando el chatbot responda a tus preguntas.',

    enterMessage: 'Ingresa tu mensaje',
    canHeplYou: '¿Le puedo ayudar en algo?',
    notesChatBot:
        'La información es generada por inteligencia artificial. Consulta datos importantes.',

    // Auth
    titleSignIn: 'Bienvenido de nuevo a SafeTravy',
    password: 'Contraseña',
    continue: 'Continuar',
    forgotpassword: 'Has olvidado tu contraseña',
    signInGoogle: 'Iniciar sesión con Google',
    signInFacebook: 'Iniciar sesión con Facebook',
    haventAccount: '¿No tienes una cuenta?',
    signUp: 'Inscribirse',
    errorEmailPassword:
        'Contraseña o correo electrónico incorrectos. ¡Inténtalo de nuevo!',

    titleSignUp: 'Bienvenido a SafeTravy',
    confirmPassword: 'Confirmar contraseña',
    agreeTerms: 'Al registrarse, usted acepta los',
    terms: 'Términos y condiciones',
    policy: 'Política de privacidad',
    signUpGoogle: 'registrarse en Google',
    signUnFacebook: 'regístrate en Facebook',
    existAccount: '¿Ya tiene una cuenta?',

    signIn: 'Iniciar sesión',
    forgotPasswordTitle: 'Contraseña olvidada',
    messageSendCode:
        'Introduce tu correo electrónico para restablecer tu contraseña. Le enviaremos un código',
    rememberPassword: 'Erinnern Sie sich an Ihr Passwort?',

    resetPassword: 'Restablecer contraseña',
    verificationCodeMessage:
        'Introduzca el código de verificación enviado a su correo electrónico',
    sendAgain: 'Enviar de nuevo ',

    newPasswordTitle: 'Ingresar nueva contraseña',
    newPasswordMessage: 'Ingresar y confirmar nueva contraseña',
    newPassword: 'Nueva contraseña',
    confirmNewPassword: 'Confirmar nueva contraseña',
    passwordChangedSuccessfully: 'La contraseña ha sido cambiada exitosamente',
    sunrise: 'Amanecer',
    sunset: 'Sunset',
    realFeel: 'Se siente como',
    wind: 'Viento',
    pressure: 'Presión',
    humidity: 'Humedad',
    detailedForecast: 'Previsión detallada',
    night: 'Noche',
    morning: 'Mañana',
    day: 'Día',
    evening: 'Tarde',
    temperature: 'Temperatura',
    windMetric: 'Viento, m/seg',
    feelsLike: 'Se siente como',
    viceMetric: 'Tornillo de banco, mm',
    humidityTable: 'Humedad, %.',
    precipitationProbability: 'Probabilidad de precipitaciones',
    dangerWarning: 'Advertencia de peligro',
    UVIndex: 'Índice UV',
    humidityForecast: 'Humedad:',
    emptyAlert: 'Parece que todo está tranquilo',
    emptyAlertCurrentCity: 'en la ciudad actual',

    // subscribe
    modalTitle: 'Solicitud recibida',
    subtitleModal:
        'La nueva suscripción se activará en unos minutos. Pero las acciones son correctas.',
    contactUsTitle: 'Contacta con nosotras',
}
